<template>
  <div class="page">
    <div class="content flex_wrap" v-if="list.length != 0">
      <div
        class="list_item transition flex_direction_column mt-37px"
        v-for="item in list"
        :key="item.id"
        @click="newsClick(item.id)"
      >
        <div class="bg relative">
          <img class="w-70px h-31px absolute -left-5px" src="@/assets/image/picture/推荐.png" alt="" />
          <div
            class="text-base text-white bottom-0 absolute text-center w-328px h-40px leading-40px"
            style="font-family: PingFang SC; background-color: rgba(0, 0, 0, 0.2)"
          >
            2023梅里100极限耐力赛 13张相片
          </div>
        </div>
        <!-- <img class="big" src="https://tx-match.oss-cn-shanghai.aliyuncs.com/plat/carousel/2023/3/29/16_56_20_437.jpg" /> -->
      </div>
    </div>

    <div class="pagination" v-if="list.length != 0">
      <el-pagination
        @current-change="currentChange"
        :page-size="15"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <Lack
      v-if="list.length == 0"
      imgWidth="102px"
      imgHeight="86px"
      text="暂无资讯资讯"
      :imgSrc="require('@/assets/image/img/29.png')"
    ></Lack>

    <Loading v-if="isShow"></Loading>
  </div>
</template>

<script>
import { newClassify, newsList } from '@/service/api/news'
import Lack from '@/components/lack/lack.vue'
export default {
  components: { Lack },
  data() {
    return {
      currentIndex: 0,
      nav: [],
      list: [],
      params: {
        class_id: 0,
        page: 1
      },
      total: 0,
      isShow: true
    }
  },

  mounted() {
    // 新闻分类
    this.newClassify()
  },

  methods: {
    // 新闻分类
    async newClassify() {
      const res = await newClassify()
      this.nav = res.msg
      this.newsList()
    },
    // 新闻列表
    async newsList() {
      const res = await newsList(this.params)
      this.list = res.msg.data
      this.total = res.msg.total
      this.isShow = false
      // console.log(this.list)
    },
    navClick(index) {
      this.currentIndex = index
      this.params.class_id = index
      this.newsList()
    },
    newsClick(id) {
      this.$router.push({
        path: '/picture-detail',
        query: {
          id
        }
      })
    },
    currentChange(page) {
      this.params.page = page
      this.newsList()
    }
  }
}
</script>

<style lang="less" scoped>
.bg {
  background: url('https://tx-match.oss-cn-shanghai.aliyuncs.com/plat/carousel/2023/3/29/16_56_20_437.jpg') no-repeat;
  background-size: 100% 100%;
  width: 328px;
  height: 205px;
  border-radius: 5px;
  object-fit: cover;
}
/deep/ .el-pager li {
  font-weight: normal;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: @color_one;
  color: #fff;
}
.pagination {
  display: flex;
  margin-top: 29px;

  justify-content: center;
}
.page {
  padding-bottom: 38px;
  background-color: #fff;
}
.nav {
  height: 60px;
  // margin-bottom: 30px;
  // border-bottom: 1px solid #eeeeee;
  .active {
    color: #fff;
    background-color: @color_one;
  }
  .item {
    width: 174px;
    height: 60px;
    cursor: pointer;
    line-height: 60px;
    text-align: center;
    border-radius: 5px;

    font-size: @font_size_18;
  }
}
.list_item {
  width: 328px;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 40px;

  &:nth-child(4n) {
    margin-right: 0;
  }

  span {
    height: 22px;
    margin-top: 16px;
    line-height: 22px;
    font-size: @font_size_16;
  }
  .time {
    margin-top: 6px;
    // img {
    //   width: 14px;
    //   height: 14px;
    //   margin-right: 5px;
    //   object-fit: cover;
    // }
    span {
      &:nth-child(1) {
        margin-right: 33px;
      }
      height: 20px;
      margin-top: 0px;
      line-height: 20px;
      color: @color_twenty;
      font-size: @font_size_14;
    }
  }
}
</style>
