<template>
  <div>
    <div class="header">
      <img v-if="$store.state.config.news_banner" :src="$store.state.config.news_banner" />
    </div>

    <pictureList></pictureList>
  </div>
</template>

<script>
import pictureList from '@/components/list/pictureList.vue'
export default {
  components: { pictureList },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
